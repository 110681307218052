import React from "react"
import Layout from "../components/layout"
import {
  Title,
  Overview,
  Toolset,
  Involvement,
  Visit,
  WorkedWith,
  Project,
  Car,
} from "../components/parts"

const Semanitcs3 = () => (
  <Layout title="Semanitcs3">
    <Title heading="Semanitcs3" sub="Ecommerce data solutions" />
    <Overview
      project="semantics3"
      text={[
        "Semantics3 (YC W13) sells Ecommerce data solutions at scale. Hearst Communications, Inc now acquired Semantics3.",
        "As the first designer to be hired by the company, I implemented a unified visual language framework across all products and assets and revamped the user journeys. As a front-end developer, I built interactive dashboards for the API playground and a dashboard for an analytics product.",
      ]}
    />

    <Project
      heading="Shopify analytics"
      sub="Actionable insights for your online store"
      style={{ marginBottom: 0 }}
    />
    <div className="section-bottom">
      <Car
        images={[1, 2, 3, 4, 5].map(i => `/images/semantics3/shopify-${i}.png`)}
        bordered
      />
    </div>

    <Project
      heading="API playground"
      sub="Understanding & test driving API, visually"
      style={{ marginBottom: 0 }}
    />
    <div className="section-bottom">
      <Car
        images={[1, 2, 3, 4].map(i => `/images/semantics3/api-${i}.png`)}
        bordered
      />
    </div>

    <Project
      heading="UPC Lookup"
      sub="Free lookup tool for SEO"
      style={{ marginBottom: 0 }}
    />
    <div className="section-bottom">
      <Car
        images={[1, 2].map(i => `/images/semantics3/lookup-${i}.png`)}
        bordered
      />
    </div>

    <Project
      heading="Thanksgiving playbook 2016"
      sub="Should you buy this thanksgiving?"
      style={{ marginBottom: 0 }}
    />
    <div className="section-bottom">
      <Car
        images={[2, 3].map(i => `/images/semantics3/playbook-${i}.png`)}
        bordered
      />
    </div>

    <Project
      heading="Website"
      sub="Landing page explainers"
      style={{ marginBottom: 0 }}
    />
    <div className="section-bottom">
      <img
        src="/images/semantics3/website-1.png"
        alt="website"
        className="bordered"
      />
    </div>

    <Toolset project="semantics3" />
    <Involvement project="semantics3" />
    <Visit project="semantics3" />
    <WorkedWith project="semantics3" />
  </Layout>
)

export default Semanitcs3
